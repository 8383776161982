import { template as template_f94e62ac10b444189ea12f433a9a1e49 } from "@ember/template-compiler";
const FKLabel = template_f94e62ac10b444189ea12f433a9a1e49(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
