import { template as template_e52350c3104b4151807f06d5c9962ed7 } from "@ember/template-compiler";
const FKText = template_e52350c3104b4151807f06d5c9962ed7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
