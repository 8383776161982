import { template as template_0924595eacc14684ad08953283f84a61 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0924595eacc14684ad08953283f84a61(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
