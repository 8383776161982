import { template as template_a74781db01a64baeb1b5b43e760908e9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a74781db01a64baeb1b5b43e760908e9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
